import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import Footer from '../Home/Footer';
import VerifyEmail from "./VerifyEmail.jsx"
import { validatePassword } from '../Utils/PasswordValidator.js';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import TermsModal from './TermsModel.jsx';
import { handleClinicSignUp, sendOtp, verifyOtp } from '../../ApiHandler/AuthFunctions.js';

const ClinicSignUp = () => {
    const [formDetails, setFormDetails] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        clinicName: '',
        phoneNumber: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        addressLine2: ''
        // patients: '',
    });
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [otp, setOtp] = useState(''); //  Code entered by the user
    const [otpStage, setOtpStage] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const navigate = useNavigate();

    // Update form details when fields are changed
    const handleChange = (e) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    };

    const handlePasswordChange = (password) => {
        setFormDetails({ ...formDetails, password });
        const errors = validatePassword(password);
        setPasswordErrors(errors);
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const verifyResponse = await verifyOtp(formDetails.email, otp);
        if (verifyResponse.success) {
            // Proceed with registration after  Code verification
            await handleClinicSignUp(e, formDetails, setFormDetails, navigate);
            setOtp('');
            setOtpStage(false);
        } else {
            toast.error(verifyResponse.message, {
                position: "top-center"
            });
        }
        setLoading(false);
    };

    const handleSelectedLocation = (formattedAddress, city, state, country, postalCode) => {
        setFormDetails((prevData) => ({
            ...prevData,
            street: formattedAddress,
            city: city || prevData.city,
            state: state || prevData.state,
            country: country || prevData.country,
            postalCode: postalCode || prevData.postalCode
        }));
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            <div className="auth-container">
                {!otpStage ? (
                    <>
                        <h1>Register as Provider</h1>
                        <p>Please enter your details</p>
                        <form onSubmit={(e) => sendOtp(e, passwordErrors, formDetails.email, formDetails.phoneNumber, formDetails.password, formDetails.confirmPassword, setOtpLoading, setOtpStage)}>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={formDetails.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        placeholder="Enter your phone number with country code"
                                        value={formDetails.phoneNumber}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        maxLength={15}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Enter your password"
                                        value={formDetails.password}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                        required
                                    />
                                    {passwordErrors.length > 0 && (
                                        <ul className="password-errors">
                                            {passwordErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Enter your password again"
                                        value={formDetails.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>Provider Name</label>
                                    <input
                                        type="text"
                                        name="clinicName"
                                        placeholder="Enter provider name"
                                        value={formDetails.clinicName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Street</label>
                                    <SearchLocationInput
                                        setSelectedLocation={handleSelectedLocation}
                                        initialQuery={null}
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="Enter your city"
                                        value={formDetails.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Postal Code</label>
                                    <input
                                        type="text"
                                        name="postalCode"
                                        placeholder="Enter your postal code"
                                        value={formDetails.postalCode}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='in-row-input'>
                                <div className="form-group">
                                    <label>State</label>
                                    <input
                                        type="text"
                                        name="state"
                                        placeholder="Enter your state"
                                        value={formDetails.state}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Country</label>
                                    <input
                                        type="text"
                                        name="country"
                                        placeholder="Enter your country"
                                        value={formDetails.country}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address Line 2</label>
                                <input
                                    type="text"
                                    name="addressLine2"
                                    placeholder="Enter your address (Optional)"
                                    value={formDetails.addressLine2}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <div className="form-group">
                                <label>Estimate number of patients</label>
                                <select
                                    name="patients"
                                    value={formDetails.patients}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option>1-50</option>
                                    <option>1-100</option>
                                    <option>1-500</option>
                                    <option>1-1000</option>
                                </select>
                            </div> */}
                            <div className="checkbox-group">
                                <input
                                    type="checkbox"
                                    id="termsCheckbox"
                                    checked={termsAccepted}
                                    onChange={() => setShowTermsModal(true)}
                                    required
                                />
                                <span htmlFor="termsCheckbox">I agree to the Terms and Conditions</span>
                            </div>
                            <button type="submit" className="sign-in-button" disabled={otpLoading}>
                                {otpLoading ? 'Sending Code...' : 'Sign Up'}
                            </button>
                        </form>
                        <TermsModal show={showTermsModal} accept={() => { setShowTermsModal(false); setTermsAccepted(true); }} onClose={() => { setShowTermsModal(false) }} />
                    </>
                ) : (
                    <VerifyEmail
                        otp={otp}
                        setOtp={setOtp}
                        loading={loading}
                        handleOtpSubmit={handleOtpSubmit}
                    />
                )}
            </div>
            <Footer />
        </>
    );
};

export default ClinicSignUp;