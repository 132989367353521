import React, { useState } from 'react';
import logo from '../../Assets/Navbar/quickcare-logo.svg';
import userIcon from '../../Assets/Navbar/user-icon.svg';

const Navbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu
    const [isOpen, setIsOpen] = useState(false); // State for live chat
    const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleLoginDropdown = (e) => {
        e.stopPropagation();
        setIsLoginDropdownOpen(!isLoginDropdownOpen);
    };

    const toggleMenu = (e) => {
        e.stopPropagation(); // Prevent event from bubbling up
        setIsMenuOpen(!isMenuOpen); // Toggle mobile menu
    };

    return (
        <div className="" onClick={() => { setIsMenuOpen(false); setIsOpen(false); setIsLoginDropdownOpen(false); }}>
            <header className="nav-bg">
                <nav className="navbar">
                    <div className="left">
                        <img src={logo} alt="QuickCare Logo" className="logo-img" style={{cursor: 'pointer'}} onClick={() => window.location.href = '/'} />
                        <div className="nav-links">
                            <ul>
                                <li className="home-dropdown">
                                    <a
                                        href="# "
                                        className="home-toggle-dropdown"
                                        onClick={toggleDropdown}
                                    > For Providers <i class='bx bx-chevron-down'></i></a>
                                    {isDropdownOpen && (
                                        <ul className="home-dropdown-menu">
                                            <li><a href="/products">Sexual Health</a></li>
                                            <li><a href="/products">Cosmetics</a></li>
                                            <li><a href="/products">IV Therapy</a></li>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="right">
                        <a href="/login" > <img src={userIcon} alt="User Logo" /></a>
                        <a href="/signup" className="nav-item signup-link">Sign Up</a>
                    </div>
                    {!isMenuOpen ? (
                        <button className="menu-button" onClick={toggleMenu}>☰</button>
                    ) : (
                        <button className="close-button" onClick={toggleMenu}>✖</button>
                    )}
                </nav>
                <div className="user-icon-container" onClick={toggleLoginDropdown}>
                    <img src={userIcon} alt="User Logo" className="user-icon" />
                    {isLoginDropdownOpen && (
                        <ul className="login-dropdown">
                            <li><a href="/login">Login</a></li>
                            <li><a href="/signup">Sign Up</a></li>
                        </ul>
                    )}
                </div>
            </header>
            {isMenuOpen && (
                <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
                    <ul>
                        <li><a href="/products">Sexual Health</a></li>
                        <li><a href="/products">Cosmetics</a></li>
                        <li><a href="/products">IV Therapy</a></li>
                    </ul>
                </div>
            )}
        </div>
    )
};

export default Navbar;