import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Chatbot_assistant from '../../Assets/Chatbot_img.jpg';
const CHATBOT_API_URL = process.env.REACT_APP_CHATBOT_API_URL;

const LiveChat = ({ isOpen, setIsOpen }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [size, setSize] = useState({ width: 320, height: 480 });
  const [userType, setUserType] = useState(null);
  const chatWindowRef = useRef(null);
  const navigate = useNavigate();

  const topics = ['Sexual Health', 'Cosmetics', 'IV Therapy'];

  const sendMessage = async (msg) => {
    try {
      const response = await fetch(`${CHATBOT_API_URL}/api/chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: msg })
      });
      const data = await response.json();
      setMessages([...messages, { type: 'user', text: msg }, { type: 'bot', text: data.response }]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUserTypeSelection = (type) => {
    setUserType(type);
    sendMessage(type);
    if (type === 'PATIENT') {
      setMessages([...messages, { type: 'bot', text: 'Right now, we are only onboarding providers.' }]);
    }
  };

  const handleTopicSelection = (topic) => {
    navigate(`/provider/${topic.toLowerCase().replace(/\s+/g, '-')}`);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = chatWindowRef.current.offsetWidth;
    const startHeight = chatWindowRef.current.offsetHeight;

    const handleMouseMove = (e) => {
      setSize({
        width: Math.max(200, startWidth + (e.clientX - startX)),
        height: Math.max(300, startHeight + (e.clientY - startY))
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="livechat-container">
      {isOpen && (
        <div className="chat-window" ref={chatWindowRef} style={{ width: size.width, height: size.height }}>
          <div className="chat-header">
            <div className="agent-info">
              <img src={Chatbot_assistant} alt="Chatbot" style={{ width: '30px', height: '30px' }} />
              <div>
                <div className="agent-name">Chatbot</div>
                <div className="agent-title">Support Agent</div>
              </div>
            </div>
            <button className="close-button" onClick={() => setIsOpen(false)}>✕</button>
          </div>

          <div className="chat-messages">
            {!userType && (
              <div className="message bot">
                <div className="message-bubble">Are you a Provider?</div>
                <div>
                  {['PROVIDER'].map((type) => (
                    <button key={type} onClick={(e) => { handleUserTypeSelection(type); e.stopPropagation(); }} className="topic-button">
                      {type}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {userType === 'PROVIDER' && (
              <div className="message bot">
                <div className="message-bubble">Select a topic:</div>
                <div>
                  {topics.map((topic) => (
                    <button key={topic} onClick={(e) => { e.stopPropagation(); navigate('/products'); }} className="topic-button">
                      {topic}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {messages.map((msg, idx) => (
              <div key={idx} className={`message ${msg.type}`}>
                <div className="message-bubble">{msg.text}</div>
              </div>
            ))}
          </div>

          <div className="chat-input">
            <form
              className="input-form"
              onSubmit={(e) => {
                e.preventDefault();
                if (input.trim()) {
                  sendMessage(input);
                  setInput('');
                }
              }}
            >
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message..."
                className="message-input"
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
              />
              <button type="submit" className="send-button">→</button>
            </form>
          </div>

          <div className="resizer" onMouseDown={handleMouseDown} />
        </div>
      )}
    </div>
  );
};

export default LiveChat;
