import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;


// Function to add bunch of patients by clinic
export const handleAddPatients = async (e, patients, setPatients, setLoading) => {
    e.preventDefault();
    // Filter out patients that don't have complete details
    const filledPatients = patients.filter(patient =>
        String(patient.name || '').trim() !== '' &&
        String(patient.email || '').trim() !== '' &&
        String(patient.phone || '').trim() !== '' &&
        String(patient.age || '').trim() !== '' &&
        String(patient.street || '').trim() !== '' &&
        String(patient.city || '').trim() !== '' &&
        String(patient.postalCode || '').trim() !== '' &&
        String(patient.state || '').trim() !== '' &&
        String(patient.country || '').trim() !== ''
        // String(patient.shippingType || '').trim() !== '' &&
        // String(patient.frequency || '').trim() !== '' &&
        // String(patient.kitType || '').trim() !== ''
    );

    if (filledPatients.length === 0) {
        toast.error('Please enter all the details of patient', {
            position: 'top-center',
        });
        return;
    }

    try {
        setLoading(true);
        // Make the API call to add patients
        const response = await Axios.post(`${API_URL}/patient/add-patients`, filledPatients, {
            headers: {
                Authorization: localStorage.getItem("token"),
                'Content-Type': 'application/json',
            },
        });

        // Handle success response
        if (response.status === 201) {
            toast.success('Patients added successfully', {
                position: 'top-center',
            });
            setPatients(Array.from({ length: 10 }, () => ({
                name: '',
                email: '',
                phone: '',
                age: '',
                street: '',
                // shippingType: '',
                // frequency: '',
                // kitType: '',
                isChecked: false,
            })));
            localStorage.removeItem('patients');
        }

    } catch (error) {
        // If there is an error from the backend, display the error message from the response
        if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message, {
                position: 'top-center',
            });
        } else {
            // General server error
            toast.error('Failed to add patients. Please try again later.', {
                position: 'top-center',
            });
        }
        console.error('Error adding patients:', error);
    } finally {
        setLoading(false);
    }
};


// Function to fetch patient details 
export const fetchPateintsDetails = async (setPatients) => {
    try {
        const response = await Axios.get(`${API_URL}/patient/fetch-patients`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.status === 200) {
            setPatients(response.data);
        } else {
            console.error('Error fetching patients data');
        }

    } catch (error) {
        console.error('Error fetching patients:', error);
    }
};


// Fetch patient address using the confirmation token
export const fetchPatientAddress = async (setAddress, setError) => {
    try {
        const response = await Axios.get(`${API_URL}/patient/fetch-patient-address`, { params: { token } }, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });
        if (response.data.success) {
            setAddress(response.data.address);
        } else {
            setError(response.data.message);
        }
    } catch (error) {
        setError('Failed to retrieve address. Please try again later.');
    }
};


// Function to update patient details
export const handleUpdatePatient = async (patient_id, editDetails, setPatients, setEditingPatientId) => {
    // const { patient_name, patient_email, patient_phone, patient_street, patient_city, patient_postal_code, patient_state, patient_country, shipping_type, frequency, kit_type } = editDetails;
    const { patient_name, patient_email, patient_phone, patient_street, patient_city, patient_postal_code, patient_state, patient_country } = editDetails;

    // if (!patient_name || !patient_email || !patient_phone || !patient_street || !patient_city || !patient_postal_code || !patient_state || !patient_country || !shipping_type || !frequency || !kit_type) {

    if (!patient_name || !patient_email || !patient_phone || !patient_street || !patient_city || !patient_postal_code || !patient_state || !patient_country) {
        toast.error('Please enter all the details of the patient', {
            position: 'top-center',
        });
        return;
    }
    try {
        const response = await Axios.put(`${API_URL}/patient/update-patient/${patient_id}`, editDetails, {
            headers: {
                Authorization: localStorage.getItem("token"),
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            setPatients((prev) =>
                prev.map((patient) =>
                    patient.patient_id === patient_id ? { ...patient, ...editDetails } : patient
                )
            );
            setEditingPatientId(null);
            toast.success('Patient data updated successfully', {
                position: 'top-center',
            });
        } else {
            toast.error('Failed to update patient data', {
                position: 'top-center',
            });
            console.error('Error updating patient');
        }
    } catch (error) {
        console.error('Error updating patient:', error);
    }
};


// Function to authorize the patients by clinic
export const updatePatientsAuthorization = async (patientIds, isAuthenticated, setPatients) => {
    if (!patientIds || patientIds.length === 0) {
        toast.error('No patients selected for authorization update', {
            position: 'top-center',
        });
        return;
    }

    try {
        const response = await Axios.post(`${API_URL}/patient/authenticate`, {
            patientIds,
            authorized: isAuthenticated ? 1 : 0,
        }, {
            headers: {
                Authorization: localStorage.getItem("token"),
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            // Update the patients state based on the response
            setPatients((prev) =>
                prev.map((patient) =>
                    patientIds.includes(patient.id)
                        ? { ...patient, authorized: isAuthenticated ? 1 : 0 }
                        : patient
                )
            );
            toast.success(`Patients ${isAuthenticated ? 'authenticated' : 'de-authenticated'} successfully`, {
                position: 'top-center',
            });
        } else {
            toast.error('Failed to update patient authorization', {
                position: 'top-center',
            });
            console.error('Error updating patient authorization');
        }
    } catch (error) {
        toast.error('An error occurred while updating patient authorization', {
            position: 'top-center',
        });
        console.error('Error updating patient authorization:', error);
        throw error;
    }
};


// Function to fetch patient profile data
export const fetchPatientProfile = async (setFormData, setFetchedClinicId) => {
    try {
        const response = await Axios.get(`${API_URL}/patient/fetch-patient-profile`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            setFormData({
                name: response.data.patient_name,
                email: response.data.patient_email,
                phone: response.data.patient_phone,
                age: response.data.patient_age,
                street: response.data.patient_street,
                city: response.data.patient_city,
                postalCode: response.data.patient_postal_code,
                state: response.data.patient_state,
                country: response.data.patient_country,
                addressLine2: response.data.address_line_2,
                shippingType: response.data.shipping_type,
                frequency: response.data.frequency,
                kitType: response.data.kit_type,
                orderId: response.data.order_id,
                clinicName: response.data.clinic_name
            });
            setFetchedClinicId(response.data.clinic_id)
        } else {
            console.error('Error fetching clinic data');
        }
    } catch (error) {
        console.error('Error fetching clinic data:', error);
    }
};


// Function to update patient profile data
export const updatePatientProfile = async (e, formData) => {
    e.preventDefault();
    if (String(formData.name).trim() === '' ||
        String(formData.phone).trim() === '' ||
        String(formData.age).trim() === '' ||
        String(formData.street).trim() === '' ||
        String(formData.city).trim() === '' ||
        String(formData.postalCode).trim() === '' ||
        String(formData.state).trim() === '' ||
        String(formData.country).trim() === '' ||
        String(formData.shippingType).trim() === '' ||
        String(formData.frequency).trim() === '' ||
        String(formData.kitType).trim() === ''
    ) {
        toast.error('Please enter all the required fields', {
            position: 'top-center',
        });
        return;
    }
    try {
        const response = await Axios.post(`${API_URL}/patient/update-patient-profile`, formData, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200) {
            toast.success('Profile updated successfully', {
                position: 'top-center',
            });
        } else {
            toast.error('Error updating profile, please try again later!', {
                position: 'top-center',
            });
        }
    } catch (error) {
        console.error('Error updating clinic profile:', error);
        toast.error('Server error', {
            position: 'top-center',
        });
    }
};

// Function to track kit status
export const trackKitStatus = async (orderId, setKitStatus) => {
    try {
        const response = await Axios.post(`${API_URL}/patient/track-kit-status`, { orderId }, {
            headers: { Authorization: localStorage.getItem("token") }
        });

        if (response.data.success) {
            // Check if the status message is "Order not placed"
            if (response.data.message === 'Order not placed') {
                setKitStatus(response.data.message); // Set this message in the state
            } else {
                setKitStatus(response.data.status); // Set the actual kit status
            }
        } else {
            console.error('Error fetching kit status:', response.data.message);
            setKitStatus(response.data.message); // Set this message in the state
        }
    } catch (error) {
        console.error('Error fetching kit status:', error);
    }
};
