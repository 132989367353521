import React from 'react';
import awsLogo from '../../Assets/aws.jpg';
import shopifyLogo from '../../Assets/shopify.jpg';
import sexualHealthImg from "../../Assets/Products/sexual-health.png"; // Replace with your actual image path
import ivTherapyImg from "../../Assets/Products/iv-therapy.png"; // Replace with your actual image path
import cosmeticsImg from "../../Assets/Products/cosmetics.png";
import lockImg from "../../Assets/Products/lock.jpg";
import clockImg from "../../Assets/Products/clock.jpg";
import dollarImg from "../../Assets/Products/dollar.jpg";
import calenderImg from "../../Assets/Products/calender.jpg";
import Navbar from "./Navbar";
import Footer from './Footer';

const Products = () => {

  const services = [
    {
      title: "Sexual Health",
      subtitle: "At-Home STI & HIV Test Kits",
      description: [
        { label: "• What it is:\n", text: "Offer your patients discreet, at-home sexual health testing." },
        { label: "• Why providers love it:\n", text: "Discreet & Hassle-Free: Patients can test at home without needing clinic visits." },
        { label: "• Provider Dashboard:\n", text: "Easily track and follow up with patients." },
        { label: "• Custom Branding:\n", text: "White-label options available for your practice." },
      ],
      buttonText: "ORDER YOUR KIT NOW",
      imgSrc: sexualHealthImg,
    },
    {
      title: "IV Therapy",
      // subtitle: "Hydration & Wellness",
      description: [
        { label: "• What it is:\n", text: "Provide on-site or in-office IV therapy solutions for hydration, wellness, and recovery." },
        { label: "• Why providers love it:\n", text: "New Revenue Stream: Expand your services with high-demand IV treatments." },
        { text: "• Customizable Packages: Tailor IV formulations for different patient needs" },
        { text: "• Seamless Scheduling: Use our built-in calendar system for easy provider bookings.\n\n" },
      ],
      buttonText: "BOOK NOW",
      imgSrc: ivTherapyImg,
    },
    {
      title: "Cosmetics",
      // subtitle: "Aesthetic Treatments",
      description: [
        { label: "• What it is:\n", text: "Offer aesthetic treatments such as Botox, fillers, and skin-rejuvenation therapies." },
        { label: "• Why providers love it:\n", text: "Premium Service Offering: Stay ahead in the growing aesthetic industry." },
        { text: "• Efficient Scheduling: Built-in appointment system for provider convenience." },
        { text: "• AI-Driven Insights: Get recommendations for optimal treatment plans.\n\n\n" },
      ],
      buttonText: "BOOK NOW",
      imgSrc: cosmeticsImg,
    },
  ];

  const features = [
    {
      icon: lockImg,
      title: "HIPAA-Compliant & Secure",
    },
    {
      icon: clockImg,
      title: "Boost Revenue",
      description: "Save up to $200K/year on missed appointments.",
    },
    {
      icon: dollarImg,
      title: "Improve Access",
      description: "Reach more patients & reduce clinic congestion.",
    },
    {
      icon: calenderImg,
      title: "Save Time",
      description: "Free up to 1 hour per patient.",
    },
  ];

  return (
    <div>
      <Navbar />
      <div classname='info-section' style={{ backgroundColor: '#e7e8e6', paddingBottom: '40px' }}>
        <header className="info-header">
          <h1>Expand Your Practice with Innovative Diagnostic & Treatment Solutions</h1>
          <p>
            We provide healthcare providers with seamless solutions for sexual health testing, IV therapy,
            and aesthetic procedures—improving patient care and boosting revenue.
          </p>
        </header>

        <div className="info-services-container">
          {services.map((service, index) => (
            <div className="info-service-card" key={index}>
              <img src={service.imgSrc} alt={service.title} className="info-service-img" />
              <h2 className="info-service-title">{service.title}</h2>
              <h3 className="info-service-subtitle">{service.subtitle}</h3>

              <ul className="info-service-description">
                {service.description.map((item, idx) => (
                  <li key={idx}>{item.label && <strong>{item.label} </strong>} {item.text}</li>
                ))}
              </ul>
              <div className="info-service-button-container">
                <button className="info-service-button">{service.buttonText}</button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <section className="why-choose-us">
        <h1 className="section-title">Why Choose Us?</h1>
        <div className="features-container">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <img src={feature.icon} alt={feature.title} className="icon-container" />
              <h2 className="feature-title">{feature.title}</h2>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="cta-section">
        <h1>Ready to Enhance Your Practice?</h1>
        <button className="cta-button" onClick={() => window.location.href = '/signup'}>
          SIGN UP TODAY
        </button>
      </section>

      <div className="trusted-by-section">
        <p>Trusted by</p>
        <div className="trusted-logos-container">
          <div className="trusted-logos">
            <img src={awsLogo} alt="AWS" />
            <img src={shopifyLogo} alt="Shopify" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Products;
