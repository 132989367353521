import React from 'react';
import { useNavigate } from "react-router-dom";
import RashuMangalmain from '../../Assets/AboutUs/RashuMangalmain.jpg';
import MeyPharn from '../../Assets/AboutUs/MeyPharn.jpg';
import RashuMangal from '../../Assets/AboutUs/RashuMangal.jpg';
import MohdRavish from '../../Assets/AboutUs/MohdRavish.jpg';
import RafaelRN from '../../Assets/AboutUs/RafaelRN.jpg';
import FayezSiddiqui from '../../Assets/AboutUs/FayezSiddiqui.jpg';
import GiovanniPreto from '../../Assets/AboutUs/GiovanniPreto.jpg';
import JamesCortese from '../../Assets/AboutUs/JamesCortese.jpg';
import DavidGarcia from '../../Assets/AboutUs/DavidGarcia.jpg';
import NoImage from '../../Assets/AboutUs/NoImage.jpg';
import workTogetherImage from '../../Assets/AboutUs/workTogetherImage.jpg';
import aboutUsNoImage from '../../Assets/AboutUs/aboutUsNoImage.jpg';
import LinkedIn from '../../Assets/AboutUs/LinkedIn.svg';
import Navbar from "./Navbar";
import Footer from './Footer';

function AboutUs() {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Navbar />
            <div className="about-us-container">
                <section className="about-us-section">
                    <h1>About us</h1>
                    <p className="about-us-tagline">
                        Revolutionizing Access to Healthcare Through Innovation and Personalized Solutions<br></br><br></br>
                    </p>
                </section>

                {/* Mission and Vision Section */}
                <section className="about-us-mission-vision">
                    <div className="about-us-mission-container">
                    <div class="image-container-mobile">
                            <img src={aboutUsNoImage} alt="Healthcare Movement" />
                        </div>
                        <div>
                            <div className="about-us-mission">
                                <h2>Our Mission</h2>
                                <p>
                                    “Reimagining healthcare to be fast,<br></br> accessible, and affordable—personalized to fit <br></br>every need.”
                                </p>
                            </div>
                            <div className="about-us-vision">
                                <h2>Our Vision</h2>
                                <p>
                                    To redefine healthcare with AI-driven, personalized<br></br> care and a scalable platform for providers.
                                </p>
                            </div>
                        </div>
                        <div class="image-container">
                            <img src={aboutUsNoImage} alt="Healthcare Movement" />
                        </div>
                    </div>

                </section>

                {/* Team Section */}
                <section className="about-us-team-section">
                    <h1>Our team</h1>
                    <p className='about-us-tagline'>Meet the People Behind the Mission</p>

                    <div className="about-us-team-member">
                        <div className="about-us-team-member-photo">
                            {/* Replace this div with an <img> tag if you have a photo URL */}
                            <img
                                src={RashuMangalmain}
                                alt="Rashu Mangal"
                                className="about-us-profile-photo"
                            />
                        </div>
                        <div className="about-us-team-member-details">
                            <h3><br></br>Rashu Mangal</h3>
                            <p className="about-us-role">CEO</p>
                            <a href="#" target="_blank" rel="noreferrer">
                                <img src={LinkedIn} alt="LinkedIn" className="linkedin-icon-up" style={{ padding: '0 0 10px 0' }} />
                            </a>
                            <br></br><br></br>
                            <p>
                                I grew up in a small town, helping my father run a jewelry business where I learned the
                                value of hard work, trust, and community. Those early experiences shaped my entrepreneurial
                                mindset and taught me the importance of building meaningful connections.
                            </p>
                            <p>
                                My journey took me from managing billion-dollar tech products for clients like Apple, Meta &
                                Google to leading AI-driven platforms that transformed industries. Along the way, I realized
                                that some of the most critical industries, like healthcare, were lagging behind—facing
                                challenges with accessibility, high costs, and outdated systems.
                            </p>
                            <p>
                                That realization led me to create Get Quick Care—a company built to deliver faster, smarter,
                                and more affordable healthcare solutions. I saw the gaps in modern healthcare and knew
                                technology could close them. With automation and innovation at the core, we're making
                                diagnostics easier, more accessible, and empowering people to take control of their health.
                            </p>
                            <p>
                                As someone who took risks, left home as the first in my family to chase big dreams, and pushed boundaries to break barriers, I've never backed down from a challenge. Healthcare is tough, but so am I—and I'm committed to leading this company to make a lasting impact on society and future generations.
                            </p>
                            <p>
                                At GetQuickCare, we're not just reimagining healthcare; we're building a movement to make it
                                smarter, simpler, and better for everyone.
                            </p>
                        </div>
                    </div>
                </section>
                <div className="team-container">
                    {/* Marketing Title */}
                    <div className="title marketing-title">
                        <h4>MARKETING</h4>
                    </div>

                    {/* UX & Engineering Title */}
                    <div className="title ux-title">
                        <h4>UX & ENGINEERING</h4>
                    </div>

                    {/* Advisors Title */}
                    <div className="title advisors-title">
                        <h4>ADVISORS</h4>
                    </div>

                    {/* Marketing Column */}
                    <div className="column marketing">
                        <Member
                            name="Mey Pharn"
                            role="Marketing Manager"
                            image={MeyPharn}
                            linkedin="#"
                        />
                        <Member
                            name="Brianna Villalobos"
                            role="Marketing Advisor"
                            image={NoImage}
                            linkedin="#"
                        />
                    </div>
                    <div className="title ux-title-mobile">
                        <h4>UX & ENGINEERING</h4>
                    </div>
                    {/* UX & Engineering Column */}
                    <div className="column ux-engineering">
                        <Member
                            name="Rashu Mangal"
                            role="CEO"
                            image={RashuMangal}
                            linkedin="#"
                        />
                        <Member
                            name="Mohd Ravish"
                            role="Software Engineer"
                            image={MohdRavish}
                            linkedin="#"
                        />
                        <Member
                            name="Giovanni Preto"
                            role="UX/UI Designer"
                            image={GiovanniPreto}
                            linkedin="#"
                        />
                        <Member
                            name="Fayez Siddiqui"
                            role="Data Scientist"
                            image={FayezSiddiqui}
                            linkedin="#"
                        />
                    </div>
                    <div className="title advisors-title-mobile">
                        <h4>ADVISORS</h4>
                    </div>

                    {/* Advisors Column */}
                    <div className="column advisors">
                        <Member
                            name="Rafael RN"
                            role="[Sexual Health]"
                            image={RafaelRN}
                            linkedin="#"
                        />
                        <Member
                            name="Tyler Scott RN"
                            role="[Cosmetics]"
                            image={NoImage}
                            linkedin="#"
                        />
                        <Member
                            name="James Cortese"
                            role="[Marketing]"
                            image={JamesCortese}
                            linkedin="#"
                        />
                        <Member
                            name="David Garcia"
                            role="[Provider/B2B]"
                            image={DavidGarcia}
                            linkedin="#"
                        />
                    </div>
                </div>

                {/* Let's Work Together Section */}
                <div class="work-together-section">
                    <div class="text-content">
                        <h1>Let's work together!</h1>
                        <p>Be Part of a Growing Movement That's Changing the Way Healthcare Works—For Everyone, Everywhere.</p>
                        <button class="sign-up-btn" onClick={() => { navigate("/careers") }}>Careers</button>
                    </div>
                    <div class="image-container">
                        <img src={workTogetherImage} alt="Healthcare Movement" />
                    </div>
                    <div class="image-container-mobile">
                            <img src={aboutUsNoImage} alt="Healthcare Movement" />
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

function Member({ name, role, image, linkedin }) {
    return (
        <div className="member">
            <img src={image} alt={name} className="member-image" />
            <h3>{name}</h3>
            <p>{role}</p>
            <a href={linkedin} target="_blank" rel="noreferrer">
                <img src={LinkedIn} alt="LinkedIn" className="linkedin-icon" />
            </a>
        </div>
    );
}

export default AboutUs;