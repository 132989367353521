import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrCircleInformation } from "react-icons/gr";
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { fetchClinicProfile, updateClinicProfile } from '../../ApiHandler/ClinicFunctions';

const EditProfile = () => {
    const [formData, setFormData] = useState({
        // email: '',
        phoneNumber: '',
        clinicName: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        patients: '',
        addressLine2: ''
    });
    const [loading, setLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        fetchClinicProfile(setFormData);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectedLocation = (formattedAddress, city, state, country, postalCode) => {
        setFormData((prevData) => ({
            ...prevData,
            street: formattedAddress,
            city: city || prevData.city,
            state: state || prevData.state,
            country: country || prevData.country,
            postalCode: postalCode || prevData.postalCode
        }));
    };

    return (
        <>
            <ToastContainer />
            <form className="add-patient-form">
                {/* <div className="form-group">
                    <label>Email</label>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter Your Email"
                        required
                    />
                </div> */}
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Provider Name</label>
                        <input
                            type="text"
                            name="clinicName"
                            value={formData.clinicName}
                            onChange={handleChange}
                            placeholder="Enter Provider Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone No</label>
                        <input
                            type="phone"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="Enter Your Phone Number"
                            required
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Street</label>
                        <SearchLocationInput
                            setSelectedLocation={handleSelectedLocation}
                            initialQuery={formData.street}
                        />
                    </div>
                    <div className="form-group">
                        <label>City</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            type="text"
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>State</label>
                        <input
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>

                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            placeholder="Enter Your Clinic Address"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Address Line 2</label>
                        <input
                            type="text"
                            name="addressLine2"
                            placeholder="Enter your address (Optional)"
                            value={formData.addressLine2}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Estimate Number of Patients</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select
                            name="patients"
                            value={formData.patients}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select</option>
                            <option value="1-50">1-50</option>
                            <option value="1-100">1-100</option>
                            <option value="1-500">1-500</option>
                            <option value="1-1000">1-1000</option>
                            <option value="1000+">1000+</option>
                        </select>
                        <button
                            type="button"
                            onClick={() => setIsPopupOpen(true)}
                            style={{
                                marginLeft: '8px',
                                marginBottom: '4px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                color: '#007bff',
                                fontSize: '1.5rem',
                            }}
                        >
                            <GrCircleInformation />
                        </button>
                    </div>
                </div>
                <button type="button" onClick={(e) => updateClinicProfile(e, formData, setLoading)} className="profile-save-button" disabled={loading}>
                    {loading ? 'Updating...' : 'Update'}
                </button>
            </form>
            {isPopupOpen && (
                <div className="modal-overlay" onClick={() => setIsPopupOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h4>Contact Us for Customer Onboarding</h4>
                            <span className="close-btn" onClick={() => setIsPopupOpen(false)}>&times;</span>
                        </div>
                        <div className="modal-body">
                            <p>Please contact <a href="mailto:rashu@getquickcare.com">rashu@getquickcare.com</a> to onboard 100+ customers.</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditProfile;